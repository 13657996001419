/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "node_modules/swiper/swiper.scss";
* {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
/* Then, in your global styles (styles.css or equivalent), you can set the font family for your app */

// -------------common---scss-------------

.common-margin {
  padding: .5rem var(--padding-value-1) var(--padding-value-1)
    var(--padding-value-1);
}
ion-footer {
  background-color: var(--white);
}
.product-box {
  margin-bottom: -1rem;
}
// --------ripple-effect---------
.ripple-parent {
  position: relative;
  overflow: hidden;
}

ion-ripple-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
// --------------end--------------
.sc-ion-searchbar-ios-h {
 padding: 0 !important;
 min-height: 42px !important;
}
.sc-ion-searchbar-md-h{
  padding: 0 !important;
 min-height: 42px !important;
}
// ---------header-------------------
ion-header {
  ion-col,
  ion-row,
  ion-grid {
    padding: 0;
    margin: 0;
  }
  padding: var(--padding-value-3) var(--padding-value-3) 0
    var(--padding-value-3);
  // padding: .5rem .8rem 0 .8rem;
  background-color: var(--white);
  .back-arrow {
    font-size: calc(var(--font-large) + 0.2rem);
    color: var(--black-dark-color);
  }
  .header-title {
    p {
      margin: 0;
      font-size: var(--font-medium);
    }
    .location-text {
      color: var(--primary-dark-color);
    }
    .location {
      margin: 0;
      padding-left: var(--padding-value-3);
      color: var(--black-dark-color);
      font-weight: 550;
      span {
        font-size: calc(var(--font-medium) + 0.1rem);
        font-weight: 650;
      }
    }

    .address {
      color: var(--ion-color-primary);
      font-weight: 550;
    }
  }

  .icon-section {
    padding-top: var(--padding-value-2);
    ion-icon {
      margin-left: var(--margin-value-1);
      font-size: var(--font-large);
      color: var(--black-dark-color);
    }
  }

  // .location-address {
  //   background-color: #e6feff;
  //   padding: 0.3rem var(--padding-value-1) 0.3rem 0;
  //   border-radius: var(--border-radius-1);
  //   margin: 0 0 var(--margin-value-1) 0;
  //   p {
  //     margin: 0;
  //   }
  //   ion-icon {
  //     color: var(--primary-dark-color);
  //     font-size: calc(var(--font-medium) + 0.2rem);
  //     margin-top: 0.1rem;
  //   }
  //   .truncated-text {
  //     color: var(--black-dark-color);
  //     white-space: nowrap;
  //     width: 90%;
  //     font-size: 0.9rem;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //   }
  //   .text-with-icon {
  //     position: relative;
  //     overflow: hidden;
  //   }
  //   .dropdown-icon {
  //     position: absolute;
  //     top: 2px;
  //     right: -3px;
  //     padding-left: 2rem;
  //     color: var(--black-dark-color);
  //     ion-icon {
  //       color: var(--black-dark-color);
  //     }
  //   }
  // }
  .user-icon {
    font-size: calc(var(--font-large) + 0.2rem);
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--white);
  }

  .chat-icon {
    font-size: 2rem;
    color: var(--primary-dark-color);
  }
  .call-icon {
    font-size: var(--font-large);
    color: var(--primary-dark-color);
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .search-field {
    background-color: #ffffff;
    border: 1px solid #e4e7e7;
    color: var(--black-dark-color);
    border-radius: 1.5rem;
    ion-input {
      font-size: 0.8rem;
      padding-left: var(--padding-value-2) !important;
    }

    ion-icon {
      font-size: var(--font-large);
      margin-top: 0.7rem;
      padding-left: var(--padding-value-1);
      color: var(--primary-dark-color);
    }
  }
  .product-availability {
    .outline-shadow {
      width: 85%;
      border: 2px solid #ccfdff;
      border-radius: var(--border-radius-1);
      padding: var(--padding-value-1) 0.2rem;
    }
    .mid-auto {
      margin-left: var(--margin-value-1);
    }
    .right-auto {
      margin-left: var(--margin-value-2);
    }
    .order-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h6 {
      margin: 0;
      font-size: 0.6rem;
      color: #495050;
    }
    h6 b {
      color: var(--black-dark-color);
    }
    ion-icon {
      color: var(--black-dark-color);
    }
    .sub-title {
      font-weight: 700;
    }
  }
}
// ---------end------------------
// -------add-to-cart------------
.add-to-cart {
  background-color: var(--primary-dark-color);
  border: 0.5px solid var(--primary-dark-color);
  text-align: center;
  border-radius: 0.3rem;
  height: 29px;
  color: var(--white);
  ion-icon {
    font-size: 1.2rem;
    font-weight: 800;
  }
  .minus-btn,
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-quantity {
    background-color: var(--white);
    color: var(--primary-dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-item {
    font-weight: 800;
  }
}
// ----------------end------------------
// -----------store-timing----------------
.store-timing {
  padding: var(--padding-value-1);
  h2{
    font-weight: var(font-weight-bold);
  }
  p{
    font-size: .9rem;
  }
  text-align: center;
  ion-img::part(image) {
    width: 30%;
    margin: auto;
  }
}
// --------filter-modal--scss-----------
.product-count{
  color: #798686;
  font-size: .8rem;
}
// -----btn--section--------------------
.custom-outline-btn {
  --background: var(--white);
  --color: var(--primary-dark-color);
  --border-radius: 0.7rem;
  --border-color: var(--primary-dark-color);
  --border-style: solid;
  font-size: 1rem;
  font-weight: 600;
  height: 45px;
  --box-shadow: none;
  --border-width: 1.2px;
  text-transform: capitalize;
}
.common-btn {
  --background: var(--primary-dark-color);
  font-weight: 600;
  height: 45px;
  font-size: 1rem;
  --border-radius: 0.7rem;
  text-transform: capitalize;
}
.bottom-btn {
  margin: 0 var(--margin-value-1);
}
// ----------end-------------
// --------------------------
.text-gray {
  color: #616161;
}
.back-color {
  background-color: #ededed;
  padding-top: var(--padding-value-1);
  border-radius: 5px;
}
.quantity {
  color: #d8bc41;
}
.distance {
  font-size: var(--font-medium);
  color: #71a914;
}

// -------------------------
.address-section {
  h4 {
    margin-top: 0;
    font-weight: 800;
  }
  .distance {
    font-size: var(--font-medium);
    color: #71a914;
  }
}
// ----------product-summary---------------
.product-summary {
  background-color: #ededed;
  margin-bottom: var(--margin-value-3);
  border-radius: var(--border-radius-1);
  ion-icon {
    margin-top: 0.2rem;
    color: var(--red-dark-color);
  }
  .product-icon {
    background-color: #fbe1e1;
    padding: var(--padding-value-1);
    border-radius: 50%;
  }
  h4 {
    margin: var(--margin-value-1);
  }
  .right-arrow {
    padding-top: var(--padding-value-2);
  }
}
// ----------------------------------
.has-value.sc-ion-input-md-h .input-clear-icon.sc-ion-input-md {
  visibility: visible;
  margin: -0.7rem 0.3rem 0 0;
  ion-icon {
    color: var(--red-dark-color);
  }
}

.header-md {
  -webkit-box-shadow: none;
}
.sc-ion-input-md-h {
  --border-style: solid;
  --highlight-color-focused: none;
  --highlight-color-valid: none;
  --highlight-color-invalid: none;
  --highlight-color: none;
}
.sc-ion-textarea-md-h {
  --border-style: solid;
  --highlight-color-focused: none;
  --highlight-color-valid: none;
  --highlight-color-invalid: none;
  --highlight-color: none;
}

p {
  color: var(--black-dark-color);
}
.botton-fixed {
  margin-top: var(--margin-value-3) !important;
}
.pointer {
  cursor: pointer;
}

// -----------------------------------Modals------------------------------
.card-item-modal {
  --height: 80%;
  --width: 100%;
  --border-radius: var(--margin-value-3) var(--margin-value-3) 0 0;
}
.address-modal {
  --height: 95%;
  --width: 100%;
  --border-radius: var(--border-radius-3);
}
.customer-support-modal {
  --height: 100vw;
  --width: 100%;
  --border-radius: var(--border-radius-3);
  padding: 1rem;
}

.language-modal {
  --height: 39%;
  --width: 90%;
  --border-radius: var(--border-radius-3);
}

.storeNotFound {
  --height: 90%;
  --width: 100%;
  --border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}

.storeNotFound-message-modal {
  --height: 55%;
  --width: 100%;
  --border-radius:  var(--border-radius-3) var(--border-radius-3) 0 0;
}

.request-product-modal {
  --height: 75%;
  --width: 95%;
  --border-radius: var(--border-radius-3);
}
.sort-modal {
  --height: 50%;
  --width: 100%;
  --border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}
.filter-modal {
  --height: 45%;
  --width: 100%;
  --border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}

.working-hours-modal {
  --height: 44%;
  --width: 90%;
  --border-radius: var(--border-radius-3);
}
.helpline-hours-modal {
  --height: 44%;
  --width: 90%;
  --border-radius: var(--border-radius-3);
}

.app-update-modal {
  --height: 47%;
  --width: 90%;
  --border-radius: var(--border-radius-3);
}

.buyatStore-modal {
  --height: 110vw;
  --width: 100%;
  --border-radius: var(--border-radius-3);
  padding: 1rem;
}

.location-not-allowed-modal{
  --height: 80vw;
  --width: 100%;
  --border-radius: var(--border-radius-3);
  padding: 1rem;
}

.offer-popup-modal{
  --height: 80vw;
  --width: 100%;
  --border-radius: var(--border-radius-3);
  padding: 1rem;
}

.set-user-location {
  --height: 90%;
  --width: 100%;
  --border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}

.out-of-stock-modal {
  --height: 65%;
  --width: 90%;
  --border-radius: var(--border-radius-3);
}

// ---------custom--toast-----------
.toast-background-primary {
  --background: var(--primary-light-color);
  --color: var(--black-dark-color);
  --border-radius: 6px;
  margin-top: var(--toast-margin-top, -5rem) !important; /* Default value is -5rem */
}
.toast-background-secondary {
  --background: #daf0e5;
  --color: #000;
  --border-radius: 6px;
}
// ---------Alert for login-----------------------
.custom-login-alert .alert-header {
  color: blue !important;
}

.primary-option {
  color: #71a914 !important;
}

.secondary-option {
  color: var(--black-dark-color) !important;
}
ion-radio {
  --border-color: var(--red-dark-color);
}
// -------------------------------------------------
/* Landscape mode styles */
@media screen and (orientation: landscape) {
  // ---------home--page------------------
  .banner {
    ion-img::part(image) {
      object-fit: contain;
      height: 60vh !important;
      margin: auto;
    }
  }
  .category-type {
    ion-img::part(image) {
      object-fit: contain;
      height: 30vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
  .left-section {
    padding: 0;
    margin: 0;
    position: fixed;
    height: 100%;
    padding-bottom: 3rem !important;
    overflow-y: auto;

    ion-col,
    ion-row,
    ion-grid {
      padding: 0;
      margin: 0;
    }

    ion-img::part(image) {
      object-fit: fill;
      width: 100%;
      height: 28vh !important;
      border-radius: 50%;
    }
  }

  // ----------------end---------------------

  .cart-product-card {
    background-color: var(--white);
    margin: var(--margin-value-1);
    border-radius: 0.8rem;

    p,
    h4,
    h6 {
      margin-bottom: var(--margin-value-1);
      margin-top: 0;
    }

    h4 {
      font-weight: 550;
      color: #4e4a4a;
    }

    .price-drop {
      text-decoration: line-through;
      color: #616161;
      margin-left: var(--margin-value-1);
    }

    .item-text {
      color: #616161;
    }

    .cart-item-section {
      .line-section {
        border: 1px solid #cccccc;
        border-radius: 0.8rem;
      }

      ion-img::part(image) {
        object-fit: contain;
        height: 10vh;
        margin: auto;
      }
    }
  }

  .list-product-card {
    ion-img::part(image) {
      object-fit: contain;
      height: 30vh !important;
      margin: auto;
    }
  }
  .cart-product-card {
    ion-img::part(image) {
      object-fit: contain;
      height: 35vh !important;
      margin: auto;
    }
  }

  // ---------------footer-----------------
  .add-item[_ngcontent-ng-c302874679]
    ion-img[_ngcontent-ng-c302874679]::part(image) {
    object-fit: contain !important;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 10vh !important;
    margin: auto;
  }
  // -------------------end---------------
}
// ----------------------end---------------------------
// ------------------desktop-----start----------------
@media screen and (max-width: 1450px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;

    ion-img::part(image) {
      object-fit: cover;
      width: 80px !important;
      height: 80px !important;
      margin: auto;
    }
  }

  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 20vh !important;
      margin: auto;
    }
  }
}
// ------------------------end------------------------
// ------------------desktop-----start----------------
@media screen and (max-width: 1200px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;

    ion-img::part(image) {
      object-fit: cover;
      width: 80px !important;
      height: 80px !important;
      margin: auto;
    }
  }
  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 20vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}
// ------------------------end------------------------
// ------------------laptop-----start----------------
@media screen and (max-width: 1024px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;

    ion-img::part(image) {
      object-fit: cover;
      width: 70px !important;
      height: 70px !important;
      margin: auto;
    }
  }

  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 20vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}
// ------------------------end------------------------
// ------------------tablet-view----start----------------
@media screen and (max-width: 750px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;

    ion-img::part(image) {
      object-fit: cover;
      width: 45px !important;
      height: 45px !important;
      margin: auto;
    }
  }
  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 20vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}
// ------------------tablet-view----start----------------
@media screen and (max-width: 820px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;
    ion-img::part(image) {
      object-fit: cover;
      width: 45px !important;
      height: 45px !important;
      margin: auto;
    }
  }
  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 12vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}
// ------------------------end------------------------

// ------------------mobile-view----start----------------
@media screen and (max-width: 500px) {
  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;
    ion-img::part(image) {
      object-fit: cover;
      width: 45px !important;
      height: 45px !important;
      margin: auto;
    }
  }
  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 8vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}
// ------------------------end------------------------
// ------------------mobile-view----start----------------
@media screen and (max-width: 300px) {

  .left-section {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3rem !important;
    ion-img::part(image) {
      object-fit: cover;
      width: 45px !important;
      height: 45px !important;
      margin: auto;
    }
  }
  // ----------order--list-------------------
  .cart-icon-img {
    ion-img::part(image) {
      object-fit: contain;
      height: 8vh !important;
      margin: auto;
    }
  }
  .item-image {
    ion-img::part(image) {
      object-fit: contain;
      border: 1px solid #ccc;
      border-radius: 5px;
      height: 8vh !important;
      margin: auto;
    }
  }
  // ----------------end---------------------
}


// ------------------------end------------------------
@media screen and (min-width: 768px) and (max-width: 800px) {
  .category-type {
    ion-img::part(image) {
      object-fit: cover;
      border: 1px solid #cccccc;
      border-radius: 0.8rem;
      width: 170px !important;
      height: 170px !important;
    }
  }
}

@media screen and (min-width: 320px) {
  .wallet-value {
    h6 {
      font-weight: 600;
      text-align: center;
    }
  }
}
// --------------end--------------------
@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .category-type {
    ion-img::part(image) {
      object-fit: cover;
      border: 1px solid #cccccc;
      border-radius: 0.8rem;
      width: 230px !important;
      height: 230px !important;
    }
  }
}

/**
Following is added by Gaurav to add subscription swiper and removed encapsulation.none from detail-sub-product.component file for header bug
 */
ion-card.subscription-swiper {
  .product-title {
    height: auto !important;
    margin: 0 !important;
  }

  ion-img::part(image) {
    max-height: 25vh !important;
  }

  .swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 1.5rem;
  }

  .swiper-slide {
    background: none;
  }

  .swiper-pagination {
    height: 20px;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background: #c9cece;
    margin: 0 2px;
  }

  .swiper-pagination-bullet-active {
    background: var(--ion-text-color, #000);
  }
}

.startSub {
  color: var(--primary-dark-color, #01b4bb);

  &.small {
    font-size: 0.6rem;
  }
}

//for use in subscriptions also
.custom-outline-btn-1 {
  font-size: 0.6rem;
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
  height: 25px;
  border: 0.5px solid var(--primary-dark-color);
  --background: var(--white);
  color: var(--primary-dark-color);
  border-radius: 0.3rem;
  margin: 0;
}

.custom-toolbar {
  padding: 0;
  margin: 0;
  --background: #ffffff;
  --min-height: 0;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0 0 0px 0px;
}
.alert-wrapper.sc-ion-alert-ios {
  border-radius: 13px;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
  background: #ffffff;
}

ion-button::part(native) {
  &::after {
      background-color: #fee6ef; /* Example: Change background color of pseudo-element */
  }
}